import {
  createStyles,
  Image,
  Container,
  Title,
  Button,
  Group,
  Text,
  List,
  ThemeIcon,
} from '@mantine/core';
import { IconCheck } from '@tabler/icons';
// import '@fontsource/bree-serif'

const useStyles = createStyles((theme) => ({
  inner: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: theme.spacing.xl * 4,
    paddingBottom: theme.spacing.xl * 4,
  },

  content: {
    maxWidth: 480,
    marginRight: theme.spacing.xl * 3,

    [theme.fn.smallerThan('md')]: {
      maxWidth: '100%',
      marginRight: 0,
    },
  },

  title: {
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    fontFamily: `${theme.other.fonts.logo}, ${theme.fontFamily}`,
    fontSize: 44,
    lineHeight: 1.2,
    fontWeight: 900,

    [theme.fn.smallerThan('xs')]: {
      fontSize: 28,
    },
  },

  control: {
    [theme.fn.smallerThan('xs')]: {
      flex: 1,
    },
  },

  image: {
    flex: 1,

    [theme.fn.smallerThan('md')]: {
      display: 'none',
    },
  },

  highlight: {
    position: 'relative',
    backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background,
    borderRadius: theme.radius.sm,
    padding: '4px 12px',
  },
}));

export function Content() {
  const { classes } = useStyles();
  return (
    <Container>
      <div className={classes.inner}>
        <div className={classes.content}>
          <Title className={classes.title}>
            Mobile-first
            <br/>
            QR code menus
          </Title>
          <Text color="dimmed" mt="md">
            Build and manage beautiful, digital-native, QR code menus with an
            all-in-one online editor and QR code generator.
          </Text>

          <List
            mt={30}
            spacing="sm"
            size="sm"
            icon={
              <ThemeIcon size={20} radius="xl" color='blue'>
                <IconCheck size={12} stroke={1.5} />
              </ThemeIcon>
            }
          >
            <List.Item>
              <b>Easy to read</b> – Guests won't need to zoom or scroll through awkward PDFs
            </List.Item>
            <List.Item>
              <b>Easy to build</b> – Our online editor makes it easy to add items and sections and publish when you are ready
            </List.Item>
            <List.Item>
              <b>QR code included</b> – Each menu comes with a pre-generated QR code. No need to manage your QR codes with another tool
            </List.Item>
          </List>

          <Group mt={30}>
            <Button radius="xl" size="md" color='blue' onClick={() => window.location.href = '/admin'}>
              Get started
            </Button>
          </Group>
        </div>
        <Image src={'menu-md-1165503814.jpg'} className={classes.image} />
      </div>
    </Container>
  );
}
