import { Input } from '@mantine/core';
import CreationModal from './CreationModal';

interface NewMenuModalProps {
  opened: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

export default function NewRestaurantModal({ opened, onClose, onSubmit }: NewMenuModalProps) {
  return (
    <CreationModal
      intent='new_restaurant'
      opened={opened}
      onClose={onClose}
      onSubmit={onSubmit}
      title="New Restaurant"
    >
      <Input.Wrapper label='Restaurant Name' mt='sm'>
        <Input type='text' name='name' placeholder="ex: Kellog's Diner"/>
      </Input.Wrapper>
    </CreationModal>
  );
}
