import { createStyles, Accordion, Group, Text, Container, Title, Center } from '@mantine/core';
import { useLoaderData } from 'react-router-dom';

import { getQRMenu } from '../api/menus';
import { formatCurrency } from '../components/utils';
import { QRMenu } from '../types/menu';
import supabase from '../api/supabase';

const useStyles = createStyles((theme) => ({
  title: {
    paddingTop: theme.spacing.sm,
    paddingBottom: theme.spacing.sm,
  },
  item: {
    paddingBottom: theme.spacing.md,
    paddingTop: theme.spacing.md
  },
  itemText: {
    fontFamily: theme.other.fonts.serif
  }
}));


export function loader({ params }: any) {
  return getQRMenu(supabase, params.menuSlug, true);
}

export default function MenuViewerPage() {
  const { classes } = useStyles();

  const menu = useLoaderData() as QRMenu | undefined;

  const accordianItems = menu?.sections?.map(section => (
    <Accordion.Item value={section.title} key={section.id}>
      <Accordion.Control>
        <Title order={3}>{section.title}</Title>
      </Accordion.Control>
      <Accordion.Panel>
        {section.items.map(item => (
          <div className={classes.item} key={item.id}>
            <Group position='apart'>
              <Text>{item.title}</Text>
              <Text size='xs'>{formatCurrency(item.price)}</Text>
            </Group>
            <Text size='sm' color='dimmed'>{item.description}</Text>
          </div>
        ))}
      </Accordion.Panel>
    </Accordion.Item>
  ));
  return (
    <Container>
      <Center>
        <Title className={classes.title}>{menu?.title}</Title>
      </Center>
      <Accordion multiple variant='separated' pb='md'>
        {accordianItems}
      </Accordion>
    </Container>
  );
}
