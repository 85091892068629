import { Input, Textarea, Modal, Group, Button } from '@mantine/core';
import { useCallback, useState, ChangeEvent, useEffect } from 'react';
import { QRMenuItem } from '../../types/menu';

interface NewItemModalProps {
  dish: QRMenuItem;
  isEdit: boolean;
  opened: boolean;
  onClose: () => void;
  onSubmit: (item: QRMenuItem, sectionIndex: number) => void;
  sectionIndex: number;
}

export default function NewItemModal({ dish, isEdit, opened, onClose, onSubmit, sectionIndex }: NewItemModalProps) {
  const [item, setItem] = useState<QRMenuItem>(dish);

  useEffect(() => setItem(dish), [dish]);

  const handleTitleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const newTitle = e.target.value;
    setItem((oldItem: QRMenuItem) => ({ ...oldItem, title: newTitle }));
  }, [setItem]);

  const handleDescriptionChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    const newDescription = e.target.value;
    setItem((oldItem: QRMenuItem) => ({ ...oldItem, description: newDescription }));
  }, [setItem]);

  const handlePriceChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '') {
      setItem(oldItem => ({ ...oldItem, price: undefined }));
    } else {
      const newPrice = Number(e.target.value);
      setItem(oldItem => ({ ...oldItem, price: newPrice }));
    }
  }, [setItem]);

  const handleSubmit = useCallback(() => {
    onSubmit(item, sectionIndex);
    onClose();
  }, [item, onSubmit, onClose, sectionIndex]);

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={isEdit ? 'Edit Item' : 'New Item'}
    >
      <Input.Wrapper label='Title' mt='sm'>
        <Input type='text' name='title' onChange={handleTitleChange} placeholder='ex: French Onion Soup' value={item.title} />
      </Input.Wrapper>
      <Textarea label='Description' name='description' mt='sm' onChange={handleDescriptionChange} value={item.description} />
      <Input.Wrapper label='Price' mt='sm'>
        <Input type='number' name='price' onChange={handlePriceChange} value={item.price ?? ''} />
      </Input.Wrapper>
      <Group mt='lg' position='right'>
        <Button onClick={onClose} variant='subtle'>Cancel</Button>
        <Button name='intent' onClick={handleSubmit} value='create_item'>{isEdit ? 'Update' : 'Create'}</Button>
      </Group>
    </Modal>
  );
}
