import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { MantineProvider } from '@mantine/core';
import '@fontsource/poppins';
import '@fontsource/spectral';
import '@fontsource/reem-kufi';

import LandingPage from './pages/LandingPage';
import AdminApp, {
  loader as adminLoader,
  action as adminAction,
} from './pages/AdminApp';
import RestaurantManagerPage, {
  loader as restaurantLoader,
  action as restaurantAction,
} from './pages/AdminApp/RestaurantManagerPage';
import MenuBuilderPage, {
  loader as menuLoader,
  action as menuAction,
} from './pages/AdminApp/MenuBuilderPage';
import NotFound from './pages/ErrorPages/NotFound';
import MenuNotFound from './pages/ErrorPages/MenuNotFound';
import RestaurantNotFound from './pages/ErrorPages/RestaurantNotFound';
import RestaurantViewerPage, {
  loader as restaurantViewerLoader
} from './pages/RestaurantViewerPage';
import MenuViewerPage, {
  loader as menuViewerLoader,
} from './pages/MenuViewerPage';
import ResetPasswordPage, {
  action as resetPasswordAction,
  loader as resetPasswordLoader,
} from './pages/ResetPasswordPage';

const router = createBrowserRouter([
  {
    path: '/',
    element: <LandingPage />,
    errorElement: <NotFound />
  },
  {
    path: '/admin',
    element: <AdminApp />,
    errorElement: <NotFound />,
    loader: adminLoader,
    action: adminAction,
    children: [
      {
        path: ':restaurantSlug',
        element: <RestaurantManagerPage />,
        errorElement: <RestaurantNotFound />,
        loader: restaurantLoader,
        action: restaurantAction
      },
      {
        path: ':restaurantSlug/menus/:menuSlug',
        element: <MenuBuilderPage />,
        errorElement: <MenuNotFound />,
        loader: menuLoader,
        action: menuAction
      }
    ]
  },
  {
    path: '/places/:restaurantSlug',
    element: <RestaurantViewerPage />,
    errorElement: <NotFound />,
    loader: restaurantViewerLoader,
  },
  {
    path: '/menus/:menuSlug',
    element: <MenuViewerPage />,
    errorElement: <NotFound />,
    loader: menuViewerLoader,
  },
  {
    path: '/resetPassword',
    element: <ResetPasswordPage />,
    errorElement: <NotFound />,
    action: resetPasswordAction,
    loader: resetPasswordLoader,
  },
]);

export default function App() {
  return (
    <MantineProvider
      withGlobalStyles
      withNormalizeCSS
      theme={{
        colors: {
          red: [
            "#FF7586",
            "#FF5368",
            "#FF344E",
            "#FF1935",
            "#FE001F",
            "#E7001D",
            "#D2001A",
            "#BD0017",
            "#AA0015",
            "#990013",
          ],
          yellow: [
            "#FFF7C5",
            "#FFF29C",
            "#FFED76",
            "#FFE954",
            "#FFE536",
            "#FFE11A",
            "#FFDE00",
            "#E6C800",
            "#CFB400",
            "#BAA200",
          ],
          green: [
            "#8FD58F",
            "#78CC78",
            "#62C462",
            "#4FBD4F",
            "#43B143",
            "#3DA13D",
            "#379237",
            "#318331",
            "#2D762D",
            "#286A28",
          ],
          blue: [
            "#D1E8FF",
            "#A7D4FF",
            "#80C1FF",
            "#5DB0FF",
            "#3EA0FF",
            "#2192FF",
            "#0484FF",
            "#0077E9",
            "#006BD2",
            "#0060BD",
          ],
          intenseblue: [
            "#D1CDF8",
            "#B0A9F3",
            "#9188EF",
            "#766AEA",
            "#5D4FE7",
            "#4636E3",
            "#3120E0",
            "#2C1CCA",
            "#2719B6",
            "#2317A4",
          ],
          black: [
            "#000000",
            "#000000",
            "#000000",
            "#000000",
            "#000000",
            "#000000",
            "#000000",
            "#000000",
            "#000000",
            "#000000"
          ]
        },
        defaultRadius: 'sm',
        fontFamily: 'Poppins, sans-serrif',
        fontSizes: {
          xs: 14,
          sm: 16,
          md: 18,
          lg: 20,
          xl: 22,
        },
        headings: {
          fontFamily: 'Poppins, sans-serrif',
          sizes: {
            h1: { fontSize: 30 },
            h2: { fontSize: 22 },
            h3: { fontSize: 20 },
            h4: { fontSize: 18 },
            h5: { fontSize: 16 },
            h6: { fontSize: 14 },
          }
        },
        other: {
          fonts: {
            serif: 'Spectral, serif',
            logo: '"Reem Kufi", sans-serif'
          }
        }
      }}
    >
      <RouterProvider router={router} />
    </MantineProvider>
  );
}
