import { createClient, User } from '@supabase/supabase-js';
import { SUPABASE_URL, SUPABASE_KEY } from '../config';

const supabase = createClient(SUPABASE_URL, SUPABASE_KEY);

export async function getSessionUser(): Promise<User | undefined> {
  const { data: { session }, error } = await supabase.auth.getSession();
  if (error) {
    throw error;
  }
  return session?.user;
}

export default supabase;
