import { useLoaderData, Link } from 'react-router-dom';
import { Button, Center, Container, Stack, Title, createStyles } from '@mantine/core';
import { getRestaurantWithMenus } from '../api/restaurants'
import { Restaurant } from '../types/restaurant';

const useStyles = createStyles((theme) => ({
  title: {
    paddingTop: theme.spacing.sm,
    paddingBottom: theme.spacing.sm,
  }
}));

export function loader({ params }: any) {
  return getRestaurantWithMenus(params.restaurantSlug);
}

export default function RestaurantViewerPage() {
  const { classes } = useStyles();
  const restaurant = useLoaderData() as Restaurant;

  const menuButtons = restaurant.menus?.map(menu => (
    <Link to={`/menus/${menu.slug}`} key={menu.slug} style={{flex: 1, textDecoration: 'none'}}>
      <Button
        color='black'
        variant='outline'
        radius='xl'
        fullWidth
      >
        {menu.title}
      </Button>
    </Link>
  ));

  return (
    <Container>
      <Center>
        <Title className={classes.title}>{restaurant.name}</Title>
      </Center>
      <Center>
        <Stack w='100%'>
          {menuButtons}
        </Stack>
      </Center>
    </Container>
  );
}
