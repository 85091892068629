import { useCallback, useState } from 'react';
import { Title, Divider } from '@mantine/core';
import { DndListBuilder } from './dragAndDrop/DndList';
import { QRMenuItem, QRMenuSection } from '../types/menu';
import NewItemModal from './modals/NewItemModal';
import { getNewQRMenuItem } from '../types/utils';
import DndMenuItem from './dragAndDrop/DndMenuItem';

interface MenuBuilderSectionProps {
  sectionIndex: number;
  menuSection: QRMenuSection;
  onAddItem: (item: QRMenuItem, sectionIndex: number) => void;
  onDeleteItem: (itemId: string, sectionIndex: number) => void;
  onReorder: (from: number, to: number, sectionIndex: number) => void;
}

export default function MenuBuilderSection({
  sectionIndex,
  menuSection,
  onAddItem,
  onDeleteItem,
  onReorder,
}: MenuBuilderSectionProps) {
  const [newItemModalOpen, setNewItemModalOpen] = useState(false);
  const [itemToEdit, setItemToEdit] = useState<QRMenuItem>(getNewQRMenuItem());
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const handleReorder = useCallback((from: number, to: number) => {
    onReorder(from, to, sectionIndex);
  }, [onReorder, sectionIndex]);

  const handlePutItem = useCallback((item?: QRMenuItem) => {
    setItemToEdit(item ?? getNewQRMenuItem());
    setIsEdit(!!item);
    setNewItemModalOpen(true);
  }, [setItemToEdit, setIsEdit, setNewItemModalOpen]);

  const renderItem = useCallback((item: QRMenuItem) => (
    <DndMenuItem
      item={item}
      onEditItem={() => handlePutItem(item)}
      onDeleteItem={() => onDeleteItem(item.id, sectionIndex)}
    />
  ), [handlePutItem, onDeleteItem, sectionIndex]);

  return (
    <div>
      <div>
        <Title order={3} mb='xs'>{menuSection.title}</Title>
        <DndListBuilder
          data={menuSection.items}
          newButtonLabel='New Item'
          renderItem={renderItem}
          onAddItem={() => handlePutItem()}
          onReorder={handleReorder}
        />
      </div>
      <NewItemModal
        dish={itemToEdit}
        isEdit={isEdit}
        opened={newItemModalOpen}
        sectionIndex={sectionIndex}
        onClose={() => setNewItemModalOpen(false)}
        onSubmit={onAddItem}
      />
      <Divider my='lg' />
    </div>
  );
}
