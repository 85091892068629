import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Button, Center, Group, Input, Paper, Stack, Tabs, Title } from '@mantine/core';
import { Form, useLoaderData } from 'react-router-dom';
import { IconQrcode, IconSettings } from '@tabler/icons';
import QRCode from 'react-qr-code';
import { getRestaurant, updateRestaurant, deleteRestaurant } from '../../api/restaurants';
import { Restaurant } from '../../types/restaurant';
import { SITE_BASE_URL } from '../../config';

export async function loader({ params }: any) {
  const { restaurantSlug } = params;
  const restaurant = await getRestaurant(restaurantSlug);
  return restaurant;
}

export async function action({ request }: any) {
  const formData = await request.formData();
  const intent = await formData.get('intent');
  const restaurantId = await formData.get('restaurant_id');
  if (intent === 'update_restaurant') {
    const restaurantName = await formData.get('restaurant_name');
    return await updateRestaurant(restaurantId, restaurantName);
  } else if (intent === 'delete_restaurant') {
    if (window.confirm('Are you sure you want to delete this restaurant and all of its data?')) {
      return await deleteRestaurant(restaurantId);
    }
  }
}

export default function RestaurantManagerPage() {
  const r = useLoaderData() as Restaurant;
  const [restaurant, setRestaurant] = useState<Restaurant>(r);

  // this is necessary to get the data on the page to change when navigating between restaurants
  useEffect(() => {
    setRestaurant(r);
  }, [r]);

  const handleNameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const newName = e.target.value;
    setRestaurant(r => ({ ...r, name: newName }));
  }, [setRestaurant]);

  return (
    <div>
      <Title>{restaurant.name}</Title>
      <Tabs defaultValue='settings'>

        <Tabs.List>
          <Tabs.Tab value='settings' icon={<IconSettings size={14} />}>Settings</Tabs.Tab>
          <Tabs.Tab value='qr_code' icon={<IconQrcode size={14} />}>View QR Code</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value='settings' mt='xs'>
          <Stack align='flex-start'>
            <Form method='put'>
              <Input type='hidden' name='restaurant_id' value={restaurant.id} />
              <Group align='end'>
                <Input.Wrapper label='Restaurant Name'>
                  <Input type='text' name='restaurant_name' value={restaurant.name} onChange={handleNameChange} />
                </Input.Wrapper>
                <Button
                  color='green'
                  variant='outline'
                  type='submit'
                  name='intent'
                  value='update_restaurant'
                >
                  Save
                </Button>
              </Group>
            </Form>
            <Form method='delete'>
              <Input type='hidden' name='restaurant_id' value={restaurant.id} />
              <Button
                color='red'
                variant='outline'
                type='submit'
                name='intent'
                value='delete_restaurant'
              >
                Delete Restaurant
              </Button>
            </Form>
          </Stack>
        </Tabs.Panel>

        <Tabs.Panel value='qr_code' mt='xs'>
          <Center>
            <Paper shadow='xs' p='lg'>
              <QRCode size={360} value={`${SITE_BASE_URL}/places/${restaurant.slug}`} style={{ maxWidth: '100%' }}/>
            </Paper>
          </Center>
        </Tabs.Panel>

      </Tabs>      
    </div>
  );
}
