import { Group, Flex, Paper, Text, Input, Button } from '@mantine/core';
import { Form, redirect } from 'react-router-dom';

import { FooterLinks } from '../components/layout/Footer';
import supabase from '../api/supabase';

export async function action({ request }: any) {
  const formData = await request.formData();
  const password = formData.get('password');
  const { error } = await supabase.auth.updateUser({ password })
  if (error) {
    throw error;
  }
  return redirect('/admin');
}

export async function loader() {
  const { error } = await supabase.auth.getUser();
  if (error) {
    return redirect('/');
  }
}

export default function ResetPasswordPage() {
  return (
    <div>
      <Flex mih='100vh' justify='center' align='center'>
          <Paper p='lg' w='100%' m='lg' maw={380} withBorder>
            <Group position='center'>
              <Text>Reset your password</Text>
            </Group>
            <Form>
              <Input.Wrapper label='New Password'>
                <Input type='password' name='password' />
              </Input.Wrapper>
              <Button type='submit'>Submit</Button>
            </Form>
          </Paper>
      </Flex>
      <FooterLinks data={[]}/>
    </div>
  );
}
