import { createStyles, Title } from '@mantine/core'

const useStyles = createStyles((theme) => ({
  title: {
    fontFamily: theme.other.fonts.logo,
    fontWeight: 600
  }
}));

interface LogoProps {
  color?: string
}

export default function Logo({ color }: LogoProps) {
  const { classes, theme } = useStyles();
  return (
    <Title
      color={color ?? (theme.colorScheme === 'dark' ? 'white' : 'red') }
      className={classes.title}
    >
      Quinoa
    </Title>
  );
}
