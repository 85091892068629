import { Center, Container, Text } from '@mantine/core';
import { useRouteError } from 'react-router-dom';

export default function MenuNotFound() {
  const error: any = useRouteError();

  return (
    <Container style={{ width: '100%', height: '100%' }}>
      <Center style={{ height: '100%' }}>
        <Text>Looks like this menu doesn't exist. Create a new menu or select a menu on the left.</Text>
      </Center>
    </Container>
  );
}
