import { useCallback, useEffect, useState } from 'react';
import {
  Link, Outlet, useLoaderData, redirect, useNavigate,
} from 'react-router-dom';
import {
  AppShell,
  Group,
  Header,
  MediaQuery,
  Burger,
  Avatar,
  Menu
} from '@mantine/core';
import { User } from '@supabase/supabase-js';
import { IconPower } from '@tabler/icons';

import UserContext from '../../contexts/userContext';
import { QRMenuPreview } from '../../types/menu';
import { Restaurant } from '../../types/restaurant';
import { createNewMenu, getMenusForRestaurants } from '../../api/menus';
import { getRestaurantsForUser, createNewRestaurant } from '../../api/restaurants';
import AdminNav from '../../components/AdminNav';
import NewRestaurantModal from '../../components/modals/NewRestaurantModal';
import NewMenuModal from '../../components/modals/NewMenuModal';
import supabase, { getSessionUser } from '../../api/supabase';
import { Login } from '../Login';
import Logo from '../../components/Logo';


export default function AdminApp() {
  const navigate = useNavigate();
  const {
    user: u,
    restaurants: r,
    menus: m,
  } = useLoaderData() as { user: User, restaurants: Restaurant[], menus: QRMenuPreview[] };
  const [menuModalOpened, setMenuModalOpened] = useState(false);
  const [restaurantModalOpened, setRestaurantModalOpened] = useState(false);
  const [navbarOpened, setNavbarOpened] = useState(false);
  const [restaurants, setRestaurants] = useState<Restaurant[]>(r);
  const [menus, setMenus] = useState<QRMenuPreview[]>(m);
  const [user, setUser] = useState<User | undefined>(u);

  supabase.auth.onAuthStateChange((event, session) => {
    if (event === 'SIGNED_IN') {
      setUser(session?.user);
    }
    if (event === 'SIGNED_OUT') {
      setUser(undefined);
      navigate('/');
    }
    if (event === 'PASSWORD_RECOVERY') {
      navigate('/resetPassword');
    }
    if (event === 'USER_UPDATED') {
      setUser(session?.user);
    }
  });

  useEffect(() => {
    const fetchDataAfterLogin = async () => {
      const restaurants = await getRestaurantsForUser(supabase);
      setRestaurants(restaurants)
      
      const menus = await getMenusForRestaurants(supabase, restaurants);
      setMenus(menus);
    };
    fetchDataAfterLogin();
  }, [user]);

  useEffect(() => {
    setRestaurants(r);
  }, [r]);

  useEffect(() => {
    setMenus(m);
  }, [m]);
  
  const handleLogout = useCallback(async () => {
    await supabase.auth.signOut();
  }, []);

  return (
    <UserContext.Provider value={user}>
      {user ? (
        <AppShell
          padding="sm"
          navbarOffsetBreakpoint="sm"
          navbar={
            <AdminNav
              restaurants={restaurants}
              menus={menus}
              hiddenBreakpoint="sm"
              hidden={!navbarOpened}
              onClickNewRestaurant={() => setRestaurantModalOpened(true)}
              onClickNewMenu={() => setMenuModalOpened(true)}
              onClickMenu={() => setNavbarOpened(false)}
            />
          }
          header={
            <Header height={60} p="xs">
              <Group position='apart'>
                <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
                  <Burger
                    opened={navbarOpened}
                    onClick={() => setNavbarOpened((o) => !o)}
                    size='sm'
                    mr='xl'
                  />
                </MediaQuery>
                <Link to={'/'} style={{textDecoration: 'none'}}>
                 <Logo />
                </Link>
                <Menu>
                  <Menu.Target>
                    <Avatar radius='xl' style={{cursor: 'pointer'}}>{user?.email?.charAt(0).toUpperCase()}</Avatar>
                  </Menu.Target>
                  <Menu.Dropdown>
                    <Menu.Label>{user?.email}</Menu.Label>
                    <Menu.Divider />
                    <Menu.Item icon={<IconPower size={14} />} onClick={handleLogout}>Sign Out</Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              </Group>
            </Header>
          }
          styles={(theme) => ({
            main: { backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0] },
          })}
        >
          <Outlet />
          <NewMenuModal
            restaurants={restaurants}
            opened={menuModalOpened}
            onClose={() => setMenuModalOpened(false)}
            onSubmit={() => setMenuModalOpened(false)}
          />
          <NewRestaurantModal
            opened={restaurantModalOpened}
            onClose={() => setRestaurantModalOpened(false)}
            onSubmit={() => setRestaurantModalOpened(false)}
          />
        </AppShell>
      ) : (
        <Login />
      )}
    </UserContext.Provider>
  );
}

export async function loader() {
  const user = await getSessionUser();
  if (!user) {
    return { user: undefined, restaurants: [], menus: [] };
  }
  const restaurants = await getRestaurantsForUser(supabase);
  const menus = await getMenusForRestaurants(supabase, restaurants);

  return { user, restaurants, menus };
}

export async function action({ request }: any) {
  const formData = await request.formData();
  const intent = formData.get('intent');
  if (intent === 'new_menu') {
    const {menu, restaurant} = await createNewMenu(supabase, {
      title: formData.get('title'),
      restaurantId: Number(formData.get('restaurant'))
    });
    return redirect(`/admin/${restaurant.slug}/menus/${menu.slug}`);
  } else if (intent === 'new_restaurant') {
    const restaurant = await createNewRestaurant(supabase, formData.get('name'));
    return redirect(`/admin/${restaurant.slug}`);
  }
}
