import { QRMenu, QRMenuItem, QRMenuSection, UpdateMenuRequest } from "./menu";

export function mapMenuToUpdateRequest(menu: QRMenu): UpdateMenuRequest {
  const { title, sections } = menu;
  return { title, sections };
}

export function getNewQRMenuItem(): QRMenuItem {
  return {
    id: crypto.randomUUID(),
    title: '',
    description: ''
  };
}

export function getNewQRMenu(): QRMenu {
  return {
    title: '',
    isPublic: false,
    sections: []
  };
}

export function getNewQRMenuSection(): QRMenuSection {
  return {
    id: crypto.randomUUID(),
    title: '',
    items: []
  };
}
