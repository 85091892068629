import {
  Accordion, Navbar, Group, Text, List, Button, ScrollArea, Stack, ThemeIcon, MantineNumberSize, createStyles
} from '@mantine/core';
import { IconBook } from '@tabler/icons';
import { NavLink } from 'react-router-dom';
import { QRMenuPreview } from '../types/menu';
import { Restaurant } from '../types/restaurant';

const useStyles = createStyles((theme) => ({}));

interface AdminNavProps {
  restaurants: Restaurant[];
  menus: QRMenuPreview[];
  hiddenBreakpoint?: MantineNumberSize;
  hidden: boolean;
  onClickNewRestaurant: () => void;
  onClickNewMenu: () => void;
  onClickMenu: () => void;
}

export default function AdminNav({
  restaurants,
  menus,
  hiddenBreakpoint,
  hidden,
  onClickNewRestaurant,
  onClickNewMenu,
  onClickMenu,
}: AdminNavProps) {
  const { theme } = useStyles();
  return (
    <Navbar width={{ base: '100%', sm: 300 }} p='sm' hiddenBreakpoint={hiddenBreakpoint} hidden={hidden}>
      <Navbar.Section grow component={ScrollArea} mb='md'>
      <Accordion multiple variant='separated'>
        {restaurants.map(restaurant => (
          <Accordion.Item value={restaurant.name} key={restaurant.slug}>
            <Accordion.Control>
              <NavLink
                style={
                  ({ isActive }) => (
                    isActive
                    ? ({ color: theme.colors.red[6], textDecoration: 'none' })
                    : ({ color: 'black', textDecoration: 'none'})
                  )
                }
                to={restaurant.slug}
                onClick={onClickMenu}
              >
                <Text>{restaurant.name}</Text>
              </NavLink>
            </Accordion.Control>
            <Accordion.Panel>
              <List
                center
                icon={
                  <ThemeIcon color='red' size={24} variant='outline' radius='xl' style={{border: 'none'}}>
                    <IconBook size={20} />
                  </ThemeIcon>
                }
                spacing='sm'
                size='sm'
                pt='sm'
              >
                {menus.filter(m => m.restaurantId === restaurant.id).map((menu: QRMenuPreview, i: number) => (
                  <List.Item key={menu.id}>
                    <NavLink
                      style={
                        ({ isActive }) => (
                          isActive
                          ? ({ color: theme.colors.red[6], textDecoration: 'none' })
                          : ({ color: 'black', textDecoration: 'none'})
                        )
                      }
                      to={`${restaurant.slug}/menus/${menu.slug ?? menu.id}`}
                      onClick={onClickMenu}
                    >
                      <Group>
                        <Text>{menu.title}</Text>
                      </Group>
                    </NavLink>
                  </List.Item>
                ))}
              </List>
            </Accordion.Panel>
          </Accordion.Item>
        ))}
      </Accordion>
      </Navbar.Section>
      <Navbar.Section>
        <Stack>
          <Button variant='outline' onClick={onClickNewMenu}>New Menu</Button>
          <Button variant='outline' onClick={onClickNewRestaurant}>New Restaurant</Button>
        </Stack>
      </Navbar.Section>
    </Navbar>
  );
}
