import { createStyles, ActionIcon, Badge, Group, Text } from '@mantine/core';
import { IconPencil, IconTrash } from '@tabler/icons';
import { QRMenuItem } from '../../types/menu';
import { formatCurrency } from '../utils';

const useStyles = createStyles(theme => ({
  item: {
    // opportunity to add styles here
  }
}));

interface DndMenuItemProps {
  item: QRMenuItem;
  onEditItem: () => void;
  onDeleteItem: () => void;
}

export default function DndMenuItem({ item, onEditItem, onDeleteItem }: DndMenuItemProps) {
  const { classes } = useStyles();
  return (
    <>
      <Group
        position='apart'
      >
        <Group position='left'>
          <Text className={classes.item}>{item.title}</Text>
          <Badge color="teal" variant="outline">{formatCurrency(item.price)}</Badge>
        </Group>
        <Group position='right' spacing='xs' grow>
          <ActionIcon color='indigo' onClick={onEditItem}>
            <IconPencil size={18}/>
          </ActionIcon>
          <ActionIcon color='red' onClick={onDeleteItem}>
            <IconTrash size={18}/>
          </ActionIcon>
        </Group>
      </Group>
      {item.description && (
        <Text color="dimmed" size="sm" className={classes.item}>
          {item.description} 
        </Text>
      )}
    </>
  );
}
