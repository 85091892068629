import { Input, Select } from '@mantine/core';
import { Restaurant } from '../../types/restaurant';
import CreationModal from './CreationModal';

interface NewMenuModalProps {
  restaurants: Restaurant[];
  opened: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

export default function NewMenuModal({ restaurants, opened, onClose, onSubmit }: NewMenuModalProps) {
  return (
    <CreationModal
      intent='new_menu'
      opened={opened}
      onClose={onClose}
      onSubmit={onSubmit}
      title="New Menu"
    >
      <Input.Wrapper label='Title' mt='sm'>
        <Input type='text' name='title' placeholder='ex: Brunch'/>
      </Input.Wrapper>
      <Select
        label='Restaurant'
        name='restaurant'
        placeholder='Pick a restaurant'
        data={restaurants.map(r => ({value: `${r.id}`, label: r.name}))}
      />
    </CreationModal>
  );
}
