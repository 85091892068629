import React from 'react';
import { createStyles, Button, Group } from '@mantine/core';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const useStyles = createStyles((theme) => ({
  item: {
    ...theme.fn.focusStyles(),
    display: 'flex',
    alignItems: 'center',
    borderRadius: theme.radius.md,
    border: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
    padding: `${theme.spacing.sm}px ${theme.spacing.xl}px`,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.white,
    marginBottom: theme.spacing.sm,
  },

  itemDragging: {
    boxShadow: theme.shadows.sm,
  },

  symbol: {
    fontSize: 30,
    fontWeight: 700,
    width: 60,
  },
}));

interface DndListProps {
  data: { id: string }[];
  renderItem: (item: any) => React.ReactNode;
  onReorder: (from: number, to: number) => void;
  newButtonLabel?: string;
  onAddItem?: () => void;
}

export function DndListBuilder({ data, newButtonLabel, renderItem, onAddItem, onReorder }: DndListProps) {
  const { classes, cx } = useStyles();

  const items = data.map((item, index) => (
    <Draggable key={item.id} index={index} draggableId={item.id}>
      {(provided, snapshot) => (
        <div
          className={cx(classes.item, { [classes.itemDragging]: snapshot.isDragging })}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <div style={{width: '100%'}}>
            {renderItem(item)}
          </div>
        </div>
      )}
    </Draggable>
  ));

  return (
    <DragDropContext
      onDragEnd={({ destination, source }) => onReorder(source.index, destination?.index ?? 0)}
    >
      <Droppable droppableId="dnd-list" direction="vertical">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {items}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      {newButtonLabel && (
        <Group position='center'>
          <Button variant='subtle' onClick={onAddItem}>{newButtonLabel}</Button>
        </Group>
      )}
    </DragDropContext>
  );
}
