import { useCallback, useState, ChangeEvent, useEffect } from 'react';
import { Button, Input, Group, Modal } from '@mantine/core';
import { QRMenuSection } from '../../types/menu';

interface NewSectionModalProps {
  section: QRMenuSection;
  isEdit: boolean;
  opened: boolean;
  onClose: () => void;
  onSubmit: (section: QRMenuSection) => void;
}

export default function NewSectionModal({ section, isEdit, opened, onClose, onSubmit }: NewSectionModalProps) {
  const [newSection, setNewSection] = useState<QRMenuSection>(section);

  useEffect(() => setNewSection(section), [section]);

  const handleTitleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const newTitle = e.target.value;
    setNewSection((oldSection) => ({ ...oldSection, title: newTitle }));
  }, [setNewSection]);

  const handleCreate = useCallback(() => {
    onSubmit(newSection);
  }, [onSubmit, newSection]);

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={isEdit ? 'Edit Section' : 'New Section'}
    >
      <div>
        <Input.Wrapper label='Title' mt={8}>
          <Input
            type='text'
            name='title'
            onChange={handleTitleChange}
            placeholder='ex: Appetizers'
            value={newSection.title}
          />
        </Input.Wrapper>
        <Group mt='lg' position='right'>
          <Button
            onClick={onClose}
            variant='subtle'
          >
            Cancel
          </Button> 
          <Button onClick={handleCreate}>{isEdit ? 'Update' : 'Create'}</Button>
        </Group>
      </div>
    </Modal>
  );
}
