import { Link, useLoaderData, useSubmit, Form } from 'react-router-dom';
import { Button, Center, Input, Group, Paper, Switch, Tabs, Text, Title, Stack } from '@mantine/core';
import { IconQrcode, IconSection, IconSettings, IconToolsKitchen2 } from '@tabler/icons';
import QRCode from 'react-qr-code';

import { getQRMenu, deleteMenu, updateMenu, publishMenu } from '../../api/menus';
import { QRMenu, QRMenuItem, QRMenuSection } from '../../types/menu';
import { getNewQRMenu, getNewQRMenuSection, mapMenuToUpdateRequest } from '../../types/utils';
import MenuBuilderSection from '../../components/MenuBuilderSection';
import { ChangeEvent, FormEvent, useCallback, useEffect, useState } from 'react';
import NewSectionModal from '../../components/modals/NewSectionModal';
import { DndListBuilder } from '../../components/dragAndDrop/DndList';
import DndMenuSection from '../../components/dragAndDrop/DndMenuSection';
import supabase from '../../api/supabase';
import { SITE_BASE_URL } from '../../config';


export async function loader({ params }: any) {
  const { menuSlug } = params;
  return getQRMenu(supabase, menuSlug);
}

export async function action({ request }: any) {
  const formData = await request.formData();
  const intent = await formData.get('intent');
  if (intent === 'update_menu') {
    const menu = await formData.get('menu');
    const menuId = await formData.get('menu_id');
    return await updateMenu(supabase, menuId, JSON.parse(menu));
  } else if (intent === 'delete_menu') {
    if (window.confirm('Are you sure you want to delete this menu?')) {
      const menuId = await formData.get('menu_id');
      return await deleteMenu(menuId);
    }
  } else if (intent === 'publish_menu') {
    const menuId = await formData.get('menu_id');
    // this is weird way to get a boolean from the Switch, but it works
    const shouldPublish = Boolean(await formData.get('published'));
    return await publishMenu(menuId, shouldPublish);
  }
  return null;
}

export default function MenuBuilderPage() {
  const menu = useLoaderData() as QRMenu;
  const submit = useSubmit();
  const [newMenu, setNewMenu] = useState<QRMenu>(getNewQRMenu());
  const [dirty, setDirty] = useState<boolean>(false);
  const [newSectionModalOpen, setNewSectionModalOpen] = useState<boolean>(false);
  const [sectionToEdit, setSectionToEdit] = useState<QRMenuSection>(getNewQRMenuSection());
  const [isEditSection, setIsEditSection] = useState<boolean>(false);

  useEffect(() => {
    setNewMenu(menu);
    setDirty(false);
  }, [menu]);

  const onAddItem = useCallback((item: QRMenuItem, sectionIndex: number) => {
    setNewMenu((m) => {
      const itemsList = m.sections[sectionIndex].items;
      const existingItem = itemsList.find(i => i.id === item.id);
      if (existingItem) {
        existingItem.title = item.title;
        existingItem.description = item.description;
        existingItem.price = item.price;
      } else {
        itemsList.push(item);
      }
      return { ...m };
    });
    setDirty(true);
  }, [setNewMenu, setDirty]);

  const onDeleteItem = useCallback((itemId: string, sectionIndex: number) => {
    setNewMenu((m) => {
      const itemIndex = m.sections[sectionIndex].items.findIndex(item => item.id === itemId);
      m.sections[sectionIndex].items.splice(itemIndex, 1);
      return { ...m };
    });
    setDirty(true);
  }, [setNewMenu]);

  const onReorderItems = useCallback((from: number, to: number, sectionIndex: number) => {
    setNewMenu((m) => {
      const section = m?.sections?.[sectionIndex];
      if (section) {
        const [item] = section.items.splice(from, 1);
        section.items.splice(to, 0, item);
      }
      return { ...m };
    });
    setDirty(true);
  }, [setNewMenu, setDirty]);

  const openNewSectionModal = useCallback((section?: QRMenuSection) => {
    setIsEditSection(!!section);
    setSectionToEdit(section ?? getNewQRMenuSection());
    setNewSectionModalOpen(true);
  }, [setIsEditSection, setSectionToEdit, setNewSectionModalOpen]);
  
  const onSubmitSection = useCallback((section: QRMenuSection) => {
    setNewMenu((m) => {
      const existingSectionIndex = m.sections.findIndex(s => s.id === section.id);
      if (existingSectionIndex >= 0) {
        m.sections[existingSectionIndex] = section;
      } else {
        m.sections.push(section);
      }
      return { ...m };
    });
    setDirty(true);
    setNewSectionModalOpen(false);
  }, [setNewMenu, setDirty, setNewSectionModalOpen]);

  const onReorderSections = useCallback((from: number, to: number) => {
    setNewMenu((m) => {
      const [section] = m.sections.splice(from, 1);
      m.sections.splice(to, 0, section);
      return { ...m };
    });
    setDirty(true);
  }, [setNewMenu, setDirty]);

  const onDeleteSection = useCallback((sectionId: string) => {
    if (window.confirm('Are you sure you want to delete this section?')) {
      setNewMenu((m) => {
        const sectionIndex = m.sections.findIndex(section => section.id === sectionId);
        m.sections.splice(sectionIndex, 1);
        return { ...m };
      });
      setDirty(true);
    }
  }, [setNewMenu, setDirty]);

  const handleMenuTitleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const newTitle = e.target.value;
    setNewMenu((m) => ({ ...m, title: newTitle }));
    setDirty(true);
  }, [setNewMenu, setDirty]);

  const handleSwitchChange = useCallback((e: FormEvent<HTMLFormElement>) => {
    submit(e.currentTarget);
  }, [submit]);
  
  return (
    <div>
      <Group>
        <Title>{newMenu.title}</Title>
        <Link to={`/menus/${menu.slug}`} target='_blank'>
          <Button variant='outline'>Preview</Button>
        </Link>
        <Form method='put'>
          <Input
            type='hidden'
            name='menu'
            value={JSON.stringify(mapMenuToUpdateRequest(newMenu))}
          />
          <Input type='hidden' name='menu_id' value={menu.id} />
          <Button
            color='green'
            disabled={!dirty}
            name='intent'
            value='update_menu'
            variant='filled'
            type='submit'
          >
            Save
          </Button>
        </Form>
        {dirty && <Text c='red' fz='xs'>You have unsaved changes</Text>}
      </Group>

      <Tabs defaultValue='menu'>

        <Tabs.List>
          <Tabs.Tab value='menu' icon={<IconToolsKitchen2 size={14} />}>Edit Items</Tabs.Tab>
          <Tabs.Tab value='sections' icon={<IconSection size={14} />}>Edit Sections</Tabs.Tab>
          <Tabs.Tab value='qr_code' icon={<IconQrcode size={14} />}>View QR Code</Tabs.Tab>
          <Tabs.Tab value='settings' icon={<IconSettings size={14} />}>Settings</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value='menu' mt='xs'>
          {menu?.sections?.map((menuSection: QRMenuSection, i: number) => (
            <MenuBuilderSection
              key={menuSection.id}
              menuSection={menuSection}
              onAddItem={onAddItem}
              onDeleteItem={onDeleteItem}
              onReorder={onReorderItems}
              sectionIndex={i}
            />
          ))}
        </Tabs.Panel>

        <Tabs.Panel value='sections' mt='xs'>
          <DndListBuilder
            data={menu.sections}
            newButtonLabel='New Section'
            renderItem={(section) => (
              <DndMenuSection
                section={section}
                onEditSection={() => openNewSectionModal(section)}
                onDeleteSection={onDeleteSection}
              />
            )}
            onAddItem={() => openNewSectionModal()}
            onReorder={onReorderSections}
          />
        </Tabs.Panel>

        <Tabs.Panel value='qr_code' mt='xs'>
          <Center>
            <Paper shadow='xs' p='lg'>
              <QRCode size={360} value={`${SITE_BASE_URL}/menus/${menu.slug}`} style={{ maxWidth: '100%' }}/>
            </Paper>
          </Center>
        </Tabs.Panel>

        <Tabs.Panel value='settings' mt='xs'>
          <Stack align='flex-start'>
            <Input.Wrapper label='Menu Title'>
              <Input type='text' name='menu_title' value={newMenu.title} onChange={handleMenuTitleChange} />
            </Input.Wrapper>
            <Form method='put' onChange={handleSwitchChange}>
              <Input type='hidden' name='intent' value='publish_menu' />
              <Input type='hidden' name='menu_id' value={menu.id} />
              <Switch
                checked={menu.isPublic}
                onLabel='Published'
                offLabel='Unpublished'
                label='Publish Menu'
                labelPosition='left'
                name='published'
                styles={
                  { body: { verticalAlign: 'middle' } } // fixes weird styling of switch
                }
              />
            </Form>
            <Form method='delete'>
              <Input type='hidden' name='menu_id' value={menu.id} />
              <Button
                color='red'
                variant='outline'
                type='submit'
                name='intent'
                value='delete_menu'
              >
                Delete Menu
              </Button>
            </Form>
          </Stack>
        </Tabs.Panel>

      </Tabs>

      <NewSectionModal
        section={sectionToEdit}
        isEdit={isEditSection}
        opened={newSectionModalOpen}
        onClose={() => setNewSectionModalOpen(false)}
        onSubmit={onSubmitSection}
      />
    </div>
  );
}
