import { ActionIcon, Group, Text } from '@mantine/core';
import { IconPencil, IconTrash } from '@tabler/icons';
import { QRMenuSection } from '../../types/menu';

interface DndMenuSectionProps {
  section: QRMenuSection;
  onEditSection: () => void;
  onDeleteSection: (sectionId: string) => void;
}

export default function DndMenuSection({ section, onEditSection, onDeleteSection }: DndMenuSectionProps) {
  return (
    <Group
      position='apart'
    >
      <Group position='left'>
        <Text>{section.title}</Text>
      </Group>
      <Group position='right' spacing='xs' grow>
        <ActionIcon color='indigo' onClick={onEditSection}>
          <IconPencil size={18}/>
        </ActionIcon>
        <ActionIcon color='red' onClick={() => onDeleteSection(section.id)}>
          <IconTrash size={18}/>
        </ActionIcon>
      </Group>
    </Group>
  );
}
