import React from 'react';
import { Form } from 'react-router-dom';
import {
  Modal,
  Button,
  Group,
} from '@mantine/core';

interface CreationModalProps {
  title: string;
  intent: string;
  opened: boolean;
  onClose: () => void;
  onSubmit: () => void;
  children?: React.ReactNode;
}

export default function CreationModal({ title, intent, opened, onClose, onSubmit, children }: CreationModalProps) {
  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={title}
    >
      <Form method='post' onSubmit={onSubmit}>
        {children}
        <Group mt='lg' position='right'>
          <Button onClick={onClose} variant='subtle'>Cancel</Button> 
          <Button type='submit' name='intent' value={intent}>Create</Button>
        </Group>
      </Form>
    </Modal>
  );
}
