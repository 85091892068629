import { Group, Flex, Paper, Text } from '@mantine/core';
import { Auth, ThemeSupa } from '@supabase/auth-ui-react';

import { FooterLinks } from '../components/layout/Footer';
import { SITE_BASE_URL } from '../config';
import supabase from '../api/supabase';

export function Login() {
  return (
    <div>
      <Flex mih='100vh' justify='center' align='center'>
          <Paper p='lg' w='100%' m='lg' maw={380} withBorder>
            <Group position='center'>
              <Text>Quinoa</Text>
            </Group>
            <Auth
              view='magic_link'
              supabaseClient={supabase}
              appearance={{ theme: ThemeSupa }}
              magicLink={true}
              showLinks={false}
              redirectTo={`${SITE_BASE_URL}/admin`}
            />
          </Paper>
      </Flex>
      <FooterLinks data={[]}/>
    </div>
  );
}
