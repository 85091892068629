const formatter = Intl.NumberFormat("en-US", {
  style: 'currency',
  currency: 'USD'
});

export function formatCurrency(price?: number): string {
  if (typeof price === 'number') {
    return formatter.format(price);
  }
  return "";
}
