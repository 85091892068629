import { SupabaseClient } from '@supabase/supabase-js';
import { NotFoundError, ServerError, UnauthorizedError } from '../errors';

import { Restaurant } from '../types/restaurant';
import supabase, { getSessionUser } from './supabase';

export async function getRestaurant(slug: string): Promise<Restaurant> {
  const res = await supabase
    .from('restaurants')
    .select('id, slug, name')
    .eq('slug', slug);
  
  if (res.error) {
    console.log(res.error);
    throw new ServerError(res.error.message);
  }
  if (res.data.length > 0) {
    return {
      id: res.data[0].id,
      slug: res.data[0].slug,
      name: res.data[0].name
    };
  } else {
    throw new NotFoundError('Restaurant not found');
  }
}

export async function getRestaurantWithMenus(slug: string): Promise<Restaurant> {
  const res = await supabase
    .from('restaurants')
    .select('id, slug, name, menus(*)')
    .eq('slug', slug)
    .eq('menus.is_public', true);
  
  if (res.error) {
    console.log(res.error);
    throw new ServerError(res.error.message);
  }
  if (res.data.length === 0) {
    throw new NotFoundError('Restaurant not found');
  }
  const [restaurant] = res.data;
  return {
    id: restaurant.id,
    slug: restaurant.slug,
    name: restaurant.name,
    menus: restaurant.menus.map((menu: any) => ({
      id: menu.id,
      slug: menu.slug,
      restaurantId: menu.restaurant_id,
      title: menu.title
    }))
  };
}

export async function getRestaurantsForUser(supabase: SupabaseClient): Promise<Restaurant[]> {
  const { data: { user }, error } = await supabase.auth.getUser();
  if (!user || error) {
    return [];
  }
  const res = await supabase
    .from('restaurants_users')
    .select('restaurant_id')
    .eq('user_id', user.id);
  
  if (res.error) {
    console.log(res.error);
    throw new ServerError(res.error.message);
  }

  const restaurant_ids = res.data;

  const res2 = await supabase
    .from('restaurants')
    .select('id, slug, name')
    .in('id', restaurant_ids.map(rid => rid.restaurant_id))
    .is('deleted_at', null)
    .order('name');

  if (res2.error) {
    console.log(res2.error);
    throw new ServerError(res2.error.message);
  }
  return res2.data.map(dbRestaurant => ({
    id: dbRestaurant.id,
    slug: dbRestaurant.slug,
    name: dbRestaurant.name,
  }));
}

export async function createNewRestaurant(supabase: SupabaseClient, name: string): Promise<Restaurant> {
  const user = await getSessionUser();

  if (!user) {
    throw new UnauthorizedError();
  }

  const { data, error } = await supabase.from('restaurants')
  .insert({ name })
  .select();

  if (error) {
    console.log(error);
    throw new ServerError(error.message);
  }

  const [restaurant] = data;
  const res = await supabase.from('restaurants_users')
    .insert({ restaurant_id: restaurant.id, user_id: user.id });

  if (res.error) {
    console.log(res.error);
    throw new ServerError(res.error.message);
  }

  return {
    id: restaurant.id,
    slug: restaurant.slug,
    name: restaurant.name,
  };
}

export async function updateRestaurant(restaurantId: number, newName: string): Promise<boolean> {
  const { error } = await supabase.from('restaurants')
    .update({ name: newName })
    .eq('id', restaurantId)
  
  if (error) {
    throw new ServerError(error.message);
  }

  return true;
}

export async function deleteRestaurant(restaurantId: number): Promise<boolean> {
  const menusRes = await supabase.from('menus')
    .delete()
    .eq('restaurant_id', restaurantId);
  
  if (menusRes.error) {
    console.error(menusRes.error);
    throw new ServerError(menusRes.error.message);
  }

  const restaurantUsersRes = await supabase.from('restaurants_users')
    .delete()
    .eq('restaurant_id', restaurantId);
  
  if (restaurantUsersRes.error) {
    console.error(restaurantUsersRes.error);
    throw new ServerError(restaurantUsersRes.error.message);
  }

  const restaurantRes = await supabase.from('restaurants')
    .delete()
    .eq('id', restaurantId);

  if (restaurantRes.error) {
    console.error(restaurantRes.error);
    throw new ServerError(restaurantRes.error.message);
  }

  return true;
}
