import { SupabaseClient } from '@supabase/supabase-js';
import { NotFoundError, ServerError } from '../errors';

import {
  QRMenu, QRMenuPreview, CreateMenuRequest, UpdateMenuRequest,
} from '../types/menu';
import { Restaurant } from '../types/restaurant';
import supabase from './supabase';

export async function getQRMenu(supabase: SupabaseClient, menuSlug: number, requirePublished = false): Promise<QRMenu | undefined> {
  let query = supabase.from('menus').select().eq('slug', menuSlug);
  if (requirePublished) {
    query = query.eq('is_public', true);
  }

  const { data, error} = await query

  if (error) {
    console.log(error);
    throw new ServerError(error.message);
  }
  if (data.length === 0) {
    throw new NotFoundError('Menu not found');
  }

  const [menu] = data;

  return {
    id: menu.id,
    slug: menu.slug,
    title: menu.title,
    restaurantId: menu.restaurant_id,
    isPublic: menu.is_public,
    sections: menu.data?.sections ?? []
  };
}

export async function getMenusForRestaurants(supabase: SupabaseClient, restaurants: Restaurant[]): Promise<QRMenuPreview[]> {
  const { data, error } = await supabase
    .from('menus')
    .select()
    .in('restaurant_id', restaurants.map(r => r.id))
    .order('title');
  
    if (error) {
      console.log(error);
    } else if (data) {
      return data.map(m => ({ id: m.id, slug: m.slug, restaurantId: m.restaurant_id, title: m.title }));
    }
    return [];
}

export async function createNewMenu(supabase: SupabaseClient, request: CreateMenuRequest) {
  const { data, error } = await supabase.from('menus')
    .insert({
      title: request.title,
      restaurant_id: request.restaurantId
    })
    .select('*, restaurants(*)');
  
  if (!data || error) {
    throw error;
  }
  const [menu] = data;
  const restaurant = menu.restaurants;

  return {
    menu: {
      id: menu.id,
      title: menu.title,
      restaurantId: menu.restaurant_id,
      slug: menu.slug,
      isPublic: menu.is_public,
      sections: menu.data?.sections ?? [],
    },
    restaurant: {
      id: restaurant.id,
      slug: restaurant.slug,
      name: restaurant.name
    }
  };
}

export async function updateMenu(supabase: SupabaseClient, menuId: string, menuData: UpdateMenuRequest): Promise<QRMenu> {
  const { title, sections } = menuData;
  const { data, error } = await supabase.from('menus')
    .update({ title, data: { sections } })
    .eq('id', menuId)
    .select();
  
  if (!data || error) {
    throw error;
  }

  const [menu] = data;

  return {
    id: menu.id,
    title: menu.title,
    slug: menu.slug,
    restaurantId: menu.restaurant_id,
    isPublic: menu.is_public,
    sections: menu.data?.sections ?? []
  };
}

export async function publishMenu(menuId: string, shouldPublish: boolean): Promise<boolean> {
  const { error } = await supabase.from('menus')
    .update({ is_public: shouldPublish })
    .eq('id', menuId);

  if (error) {
    console.log(error);
    throw new ServerError(error.message);
  }

  return true; // this is needed to make the action work properly
}

export async function deleteMenu(menuId: string): Promise<boolean> {
  const { error } = await supabase.from('menus').delete().eq('id', menuId)
  if (error) {
    throw error;
  }
  return true;
}
