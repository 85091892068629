import { LandingHeader } from './Header';
import { FooterLinks } from '../../components/layout/Footer';
import { Content } from './Content';

export default function LandingPage() {
  return (
    <div>
      <LandingHeader links={[]}/>
      <Content />
      <FooterLinks data={[]}/>
    </div>
  );
}
