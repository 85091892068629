import { Center, Container, Text } from '@mantine/core';
import { useRouteError } from 'react-router-dom';
import { LandingHeader } from '../LandingPage/Header';

export default function NotFound() {
  const error: any = useRouteError();
  console.error(error);

  return (
    <div>
      <LandingHeader links={[]} hideSignIn={true} />
      <Container px='lg'>
        <Center style={{ height: 500 }}>
          <Text ta='center'>Sorry, we can't find this page.</Text>
        </Center>
      </Container>
    </div>
  );
}
